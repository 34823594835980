<template>
    <div class="clientcabin">
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <p class="headline text-center my-4 pb-md-6">
                        Thank you for your order!
                    </p>
                    <v-img
                        src="@/assets/img/clientcabin/thanks-order.jpg"
                        contain
                    />
                    <div class="pt-8 pt-md-14 text-center text-body-1">
                        <p class="text-h6">Here's What's Next:</p>
                        <p>
                            You're all set and ready to go. From here, our team
                            will be in touch within the next 24 hours to get a
                            few more details about your business.

                            <span v-if="hasOrder">
                                From there, we'll start the process of creating
                                your
                                <span v-if="isSnapshot">
                                    Snapshot Report so you can see where you
                                    currently need to improve neighborhood
                                    exposure for services you offer.
                                </span>
                                <span v-if="isCheckout">
                                    Hyper Local Ad Campaign so you can start
                                    seeing results from the visibility the
                                    campaign generates.
                                </span>
                            </span>
                        </p>
                        <p>
                            If you have any questions, feel free to contact us
                            <a :href="mailLink">here</a>
                            .
                        </p>
                        <p>We look forward to working with you!</p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

const ThanksProps = Vue.extend({
    name: 'Thanks',
    props: {
        product: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class Thanks extends ThanksProps {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get isSnapshot() {
        return this.product === 'snapshot';
    }

    get isCheckout() {
        return this.product === 'checkout';
    }

    get hasOrder() {
        return this.isSnapshot || this.isCheckout;
    }

    get mailLink() {
        return `mailto:${this.options?.reseller_email}`;
    }
}
</script>
